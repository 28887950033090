import React from 'react';

export default function Footer() {
  return (
    <footer className="bg-black small text-center text-white-50">
      <div className="footer-container">
        <p>hello@lastfridaysupperclub.com  </p>
      </div>
      <div className="footer-container">
        <p>© {new Date().getFullYear()}{' '}<a href="https://lastfridaysupperclub.com/">Last Friday Supper Club</a></p>        
      </div>
    </footer>
  );
}
